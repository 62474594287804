import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";




const DeletePlanOverlay = ({open, site,close, ...props}) => {

console.log('site',site.site_id)

	const deletePlan = (e) => {
		e.preventDefault();
		close();

		 

	  	props.isLoading({
			message:"Suppression Direction : "+site.site_name,
			animate:true
		});  


		api.post('/api/site/delete/'+site.site_id)

		.then((response) => {
			if(response.data.success) {
				props.setOverlay({
					message: 'Direction '+site.site_name+' supprimé.',
					label: "success",
					button: {
						type: "close",
						label: "OK",
						link: "javascript:void(0)"
					}
				});
			}

		  	props.doneLoading();
			  props.loadSites(); 
		}).catch(err =>    props.doneLoading()  );


	}

	return (
		<div className={open ? "ui-overlay open" : "ui-overlay"}>
			<div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
				<button onClick={e => { close(); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
				<FontAwesomeIcon icon={faTimes} />
				</button>

				Voulez-vous vraiment Supprimer Cette Direction?<br />
				Cette Action Est Irreversible
				<div className={"delete-buttons"}>
				<button  onClick={e => { close(); } } className={"btn btn-info"}>Non</button>
				<button onClick={deletePlan} className={"btn btn-danger ml-2"}>Supprimer</button>
				</div>
			</div>
		</div>
	)
}

export default DeletePlanOverlay;
