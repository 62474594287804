import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUndo,
  faFileExport,
  faChevronDown,
  faTrash,
  faPen,
  faEdit,
  faAngleRight,
  faCheck,
  faMapMarker,
  faLock,
  faSpinner,
  faPlus,
  faCheckDouble,
  faTimes,
  faCheckCircle,
  faStickyNote,
  faReply,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import MoreInfoDialog, { Item } from "../../../Utils/MoreInfoDialog";
import moment from "moment";
import CheckOrTime from "./../components/CheckOrTime";
import Restricted from "../../../Utils/Restricted";
import api from "../../../Utils/Api";
import SSRTests from "./Tests";
import RenderComponent from "../../../Utils/RenderComponent";
import Constants from "../../../Utils/Constants";
import ApiService from "../../../Utils/ApiService";
import { useSelector } from "react-redux";
import NotesReturn from "./Tests/NotesReturn";
import SubSite from "./Tests/SubSite";

import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

const ItemTaskRow = ({
  task,
  key,
  /*exportPM,*/ deleteTask,
  editTask,
  returnTask,
  closeTask,
  acceptTask,
  rejectTask,
  showNewReplan,
  addDataTask,
  ...props
}) => {
  // const i = key;

  const { session } = useSelector((state) => ({ session: state.session }));
  const [activeTest, selectTest] = useState("clockin");
  const [showDetail, setShowDetail] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const exportForm = useRef(null);
  const detailInput = useRef(null);
  const [polColor, setPolColor] = useState("#0fcc04");
  //useEffect(data, [])

  // useEffect(() => {
  //   if(polColor){
  //       loadKML()
  //      // setKml(parser.parseFromString(file, "text/xml"))

  //   }
  // }, [polColor]);
  const selectTask = (show = true) => {
    if (!showDetail) {
      setLoading(true);
      if (session.user.role != "agent") {
        ApiService.fetchInventoryItemDetail(task.item_code).then((res) => {
          console.log("fetchInventoryItemDetail ", res.data.sites[0]);
          if (res.data.success && res.data.total > 0) {
            setDataLoaded(true);
            setData(res.data.sites[0]);
          }
          setLoading(false);
        });
      } else {
        ApiService.fetchInventoryTaskAgentDetail(task.item_code).then((res) => {
          if (res.data.success && res.data.total > 0) {
            setDataLoaded(true);
            //  setData(res.data.tasks[0]);
          }
          setLoading(false);
        });
      }
    }
    setShowDetail(!showDetail);
  };

  // const  loadKML = () => {
  //     //setLoading(true)
  //     api.get(`/api/cosite/getData/${1}`).then(res => {
  //       if(res.data){
  //             let converted = res.data.data
  //             let zoom = res.data.zoom ? res.data.zoom : 5
  //             let lat = res.data.lat ? res.data.lat : 36.000
  //             let lng = res.data.lng ? res.data.lng : 2.09
  //            // setKml(converted)
  //             const container = document.getElementById('mapId'+1)
  //             if( container ){

  //                 let  map = L.map('mapId'+1 ,
  //                                     { style: {height: '220px'}}).setView([lat, lng], zoom  )
  //                 console.log('map => '+ map)
  //                 let buildLayer = L.geoJSON(converted ,
  //                     {
  //                     style: function(feature) {
  //                         return {
  //                             color : feature && feature.properties && feature.properties.fill ? feature.properties.fill : polColor,
  //                             weight: feature && feature.properties && feature.properties['stroke-width'] ?  feature.properties['stroke-width'] : 1
  //                         };
  //                         // switch (feature.properties.name) {
  //                         //     case 'Marker 2': return {color: "#F44336"};
  //                         //     case 'Democrat':   return {color: "#0000ff"};
  //                         // }
  //                     }}
  //                     )
  //                    // setFeatures([converted])

  //                    // L.control.layers(baseMaps).addTo(map);

  //                     L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //                         attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  //                     }).addTo(map);
  //                     var drawnItems = new L.FeatureGroup();
  //                     map.addLayer(drawnItems);
  //                     // setFeatureGroup(drawnItems)
  //                     buildLayer.eachLayer(function (layer) {
  //                         drawnItems.addLayer(layer);
  //                         L.PM.reInitLayer(layer);

  //                     });
  //                     // setBuildL(buildLayer)
  //                     // setMapContainer(map)
  //                     // DrawingContainer(map, drawnItems)
  //             }
  //             //setLoading(false)
  //         }
  //     });
  // }
  const exportPM = (anomalies) => {
    setLoading(true);
    if (anomalies) {
      exportForm.current.action =
        Constants.apiEndPoints.EXPORT_SSR_TASK_ANOMALIES(task.task_id);
    } else {
      exportForm.current.action = Constants.apiEndPoints.EXPORT_SSR_TASK(
        task.task_id
      );
    }

    if (!dataLoaded)
      ApiService.fetchSSRTaskDetail(task.task_id).then((res) => {
        if (res.data.success && res.data.total > 0) {
          setDataLoaded(true);
          setData(res.data.tasks[0]);
          detailInput.current.value = JSON.stringify(res.data.tasks[0]);
          exportForm.current.submit();
          setLoading(false);
        }
        setLoading(false);
      });
    else {
      detailInput.current.value = JSON.stringify(data);
      exportForm.current.submit();
      setLoading(false);
    }
  };

  const exportPMXLSX = (anomalies) => {
    setLoading(true);

    exportForm.current.action = Constants.apiEndPoints.EXPORTPDF_SSR_TASK(
      task.task_id
    );

    if (!dataLoaded)
      ApiService.fetchSSRTaskDetail(task.task_id).then((res) => {
        if (res.data.success && res.data.total > 0) {
          setDataLoaded(true);
          setData(res.data.tasks[0]);
          detailInput.current.value = JSON.stringify(res.data.tasks[0]);
          exportForm.current.submit();
          setLoading(false);
        }
        setLoading(false);
      });
    else {
      detailInput.current.value = JSON.stringify(data);
      exportForm.current.submit();
      setLoading(false);
    }
  };

  const exportPMBatXLSX = (anomalies) => {
    // domtoimage.toBlob(document.getElementById('mapId'+1))
    //   .then(function (blob) {
    //     saveAs(blob, 'my-node.png');

    //   });

    setLoading(true);

    exportForm.current.action = Constants.apiEndPoints.EXPORTXLSXZONE_SSR_TASK(
      task.task_id
    );

    if (!dataLoaded)
      ApiService.fetchSSRTaskDetail(task.task_id).then((res) => {
        if (res.data.success && res.data.total > 0) {
          setDataLoaded(true);
          setData(res.data.tasks[0]);
          detailInput.current.value = JSON.stringify(res.data.tasks[0]);
          exportForm.current.submit();
          setLoading(false);
        }
        setLoading(false);
      });
    else {
      detailInput.current.value = JSON.stringify(data);
      exportForm.current.submit();
      setLoading(false);
    }
  };
  onchange = (
    id,
    test,
    field,
    value,
    isTable = false,
    isPower = false,
    att = ""
  ) => {
    // const value = e.target ? e.target.value : e.value;

    let d = {
      pm: id,
      test: test,
      field: field,
      value: value,
      isTable: isTable,
      isPower: isPower,
      att: att,
    };
    setLoading(true);
    ApiService.editTest(d).then((res) => {
      if (res.data.success) {
        setDataLoaded(true);
        let array = data;
        array.task_tests["access"].data = res.data.data;
        setData(array);
        // detailInput.current.value = JSON.stringify(res.data.tasks[0]);
        // exportForm.current.submit();
        setLoading(false);
      }
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <form method="post" ref={exportForm} target={"_blank"}>
        <input type="hidden" name={"pm"} ref={detailInput} />
        <input type="hidden" name={"token"} value={session.user.token} />
      </form>
      <div className={"row "}>
        <div className={"col-md-12"}>
          <div className={"row ctable-row"}>
            <div className={"col-md-1"}>{task.item_code}</div>

            {task.item_etat == null ? (
              <div className={"col-md-1"}>NAN</div>
            ) : (
              <div className={"col-md-1"}>{task.item_etat}</div>
            )}

            <div className={"col-md-2"}>{task.item_name}</div>

            <div className={"col-md-2"}>{task.item_numero_comptable}</div>

            {task.item_valeur == null ? (
              <div className={"col-md-2"}>NAN</div>
            ) : (
              <div className={"col-md-1"}>{task.item_valeur}</div>
            )}

            <div className={"col-md-1"}>{task.item_stru}</div>

            <div className={"col-md-1"}>{task.item_s_stru}</div>

            <div className={"col-md-1"}>{task.inventaire}</div>
            <div className={"col-md-2 task-details-col"}>
              <button
                onClick={() => selectTask(!showDetail)}
                className="task-action-btn"
              >
                <FontAwesomeIcon
                  icon={isLoading ? faSpinner : faChevronDown}
                  spin={isLoading}
                />{" "}
                Infos
              </button>

              <MoreInfoDialog title={"Plus"}>
                <Restricted scope={"pm"} permission={"delete_task"}>
                  <Item onClick={() => deleteTask(task.item_code)}>
                    <FontAwesomeIcon
                      className={"dialog-icon danger"}
                      icon={faTrash}
                    />{" "}
                    Supprimer
                  </Item>
                </Restricted>

                {/*    <Restricted scope={"pm"} permission={"delete_task"}> */}
                <Item onClick={(e) => editTask(task)}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    className={"dialog-icon danger"}
                  />{" "}
                  Modifier
                </Item>
                {/* </Restricted> */}
              </MoreInfoDialog>
            </div>
          </div>

          {showDetail && dataLoaded && data && (
            <div className={"animateRow row"}>
              <div className={"pm-wrapper col-md-12 row no-gutters"}>
                <div className={"col-md-3 pm-sidebar"}>
                  <ul>
                    <li>item code : {data.item_code}</li>
                    <li>item etat : {data.item_etat}</li>
                    <li>item id :{data.item_id}</li>
                    <li> item name :{data.item_name}</li>
                    <li>numero comptable : {data.item_numero_comptable}</li>
                    <li>structure :{data.item_stru}</li>
                    <li>sous structure :{data.item_s_stru}</li>
                    <li> type :{data.item_type}</li>
                    <li>valeur :{data.item_type}</li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ItemTaskRow;
