import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import {connect} from "react-redux"
import GuestTopMenu from "./Menus/GuestTopMenu";

import {readNotifications, updateNotifications, setAuthAction} from "./../Redux/actions/index";
import api from '../Utils/Api';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import CommonMenu from './Menus/CommonMenu';

const mapStateToProps = state => {
	return {
		session: state.session,
		auth: state.auth,
		user: state.user,
		notifications: state.notifications
	}
}

const mapDispatchToProps = dispatch => {
	return {
		logOut: e => dispatch(setAuthAction(e)),
		updateNotifications: e => dispatch(updateNotifications(e)),
		readNotifications: e => dispatch(readNotifications(e))
	}
}



class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			updatedNotification: false,
			menuOpen: false
		}

		this.setReadNotifications = debounce(this.setReadNotifications.bind(this), 1000);

		this.menuRef = React.createRef();


	}

	blur = () => this.setState({menuOpen: false});

	toggleMenu = () => this.setState({menuOpen: !this.state.menuOpen});

	setReadNotifications() {
		if(!this.state.updatedNotification && this.props.notifications.length) {

			const that = this;

			api.get('/api/notification/update?limit='+btoa(this.props.notifications[this.props.notifications.length-1].datetime)+'&token='+this.props.session.user.token)
			.then(function(response) {
				that.props.readNotifications(1);
				return 1;
			});
		}
	}


	render() {
		const menuOpen = this.state.menuOpen;
		return(
			<>
			<div className="header-wrap">
				<div className="header-content fluid-container">
					<div className={"header-top"}>
						<Link className={"logo"} to={"/"}>
							<img src="/logo.png" />
							Witrack Inventory
						</Link>
						<ul className={"user-profile"} >
							<li>


							<Trans i18nKey="signed_in_as" /> <span onClick={this.toggleMenu} className={"user-profile-username"}> {this.props.session.user.username}</span>



							{(this.props.session.user.role!=="agent") ? 


								<ul  className={menuOpen === true ? "user-profile-menu open" : "user-profile-menu"} ref={this.menuRef} onBlur={this.blur}>
									<li><Link to="/account"><Trans i18nKey="my_account" /></Link></li>
									<li><Link to="/account/security"><Trans i18nKey="change_my_password" /></Link></li>
									<li><a href="javascript:void(0)"  onClick={e => this.props.logOut(false)} ><Trans i18nKey="logout" /></a></li>
									<li className={"sep"}></li>
									<li><Link to="/"><Trans i18nKey="help" /></Link></li>

								</ul>
							: (
								<ul  className={menuOpen === true ? "user-profile-menu open" : "user-profile-menu"} ref={this.menuRef} onBlur={this.blur}>
								<li><a href="javascript:void(0)"  onClick={e => this.props.logOut(false)} ><Trans i18nKey="logout" /></a></li>
								</ul>
							)
							}	
								

							

							</li>
							<li><a href="#"></a></li>
						</ul>
					</div>

				</div>

			</div>

			<div className={"top-menu"}>
				{(this.props.session.auth) ? <CommonMenu /> :  <GuestTopMenu />}
			</div>

			</>
		)
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Header);
