import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SitesListContainer from "../Containers/Delivery/SitesContainer";
import AddressNewContainer from "../Containers/Delivery/AddressNewContainer";
import PlanningList from "../Components/Planning/PlanningList";
import SiteDetail from "../Components/Addresses/SiteDetail";
import StructureDetail from "../Components/Addresses/StructureDetail";
import TaskListContainer from "../Containers/Delivery/TaskListContainer";
import AnomaliesContainer from "../Containers/Delivery/AnomaliesContainer";
import CalendarPage from "../Components/Planning/CalendarPage";
import DashboardWrapper from "../Components/Dashboard/Pages/DashboardWrapper";
import Form from '../Components/Form/index'
function mapStateToProps(state) {
  return { auth: state.session.auth, session: state.session };
}

class DeliveryRouter extends Component {
  render() {
    return (
      <Switch>

            <Route exact path="/delivery/sites" component={SitesListContainer} />
            <Route exact path="/delivery/sites/new" component={AddressNewContainer} />
            <Route path="/delivery/sites/detail/:id" component={SiteDetail} />
            <Route path="/delivery/structure/detail/:id" component={StructureDetail} />


            <Route exact path="/delivery" component={TaskListContainer} />
            <Route exact path="/delivery" component={Form} />
            <Route exact path="/delivery/task" component={TaskListContainer} />
           
            <Route exact path="/delivery/task/:taskid/" component={TaskListContainer} />
            <Route exact path="/delivery/task/agent/:agentid/" component={TaskListContainer} />
            <Route exact path="/delivery/task/site/:site/" component={TaskListContainer} />
            

            {/* <Route path='/delivery/anomalies' component={AnomaliesContainer} /> */}
            {/* <Route exact path='/delivery/planning' component={PlanningList} /> */}
            {/* <Route path='/delivery/calendar' component={CalendarPage} /> */}
            {/*<Route path='/delivery/dashboard' component={DashboardWrapper} />*/}

      </Switch>
    );
  }
}

export default connect(mapStateToProps)(DeliveryRouter);
