import React, { useState } from "react";
import api from "../../../Utils/Api";
import { formToObject } from "../../../Utils/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { setOverlay, doneLoading, startLoading } from "../../../Redux/actions";


const ChangeOwnerOverlay = ({site, open,toggle, close, type = "pm", ...props}) => {


    const dispatch = useDispatch();

    const [owner, setOwner] = useState(site.site_owner);
    
    const submitForm = (e) => {

        const dispatchOverlay = e => dispatch(setOverlay(e));
        const dispatchDoneLoading = e => dispatch(doneLoading(e))
        const dispatchStartLoading = e => dispatch(startLoading(e))

		e.preventDefault();

		close();
		
		dispatchStartLoading({
			message:"Mise à jour du site",
			animate:true
		});

		const data = formToObject(e.target);


		api.post(`/api/sites/change_owner/${site.site_id}/${type}/owner`, data)
		.then((response) => {

			if(response.data.success) {

				dispatchOverlay({
					message: 'Site modifié avec succés',
					label: "success",
					button: {
						type: "close",
						label: "OK",
						link: "javascript:void(0)"
					}
				});

			} 

			dispatchDoneLoading();	
			
			props.loadSites();
		}).catch(err => dispatchDoneLoading());


        
    }
    
    const filters = useSelector(state => state.filters);

	return (
		<div className={"ui-overlay" + (open ? " open": "")}>
				<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
					<button data-package-id="0" onClick={close} className={"ui-overlay-close btn btn-danger btn-sm"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>

					<h5>Change site owner</h5>

					<form onSubmit={submitForm} method="post" encType={"multipart/form-data"}>

						<input type="hidden" name="site_id" value={site} />

						<div className="form-group mt-3">

							<label className={"font-exp"}>New site owner</label>
                            <Select 
                            defaultValue={{
                                label: owner, value: owner
                            }}
							name={"site_owner"}
                            placeholder={"Select owner..."}
                            onChange={e => setOwner(e.label)}
							options={filters.site_owners.filter(item => item.label !== "All" && item.label !== "outsource")}
							className={"mt-2"}
							/>

							

						</div>

                        <div className="form-group mt-3">

							<label className={"font-exp"}>Manager</label>
							<Select 
							name={"site_manager"}
							placeholder={"Selectionnez le manager.."}
							options={filters.managers.filter(item => item.type === owner)}
							className={"mt-2"}
							/>


                            <input type="submit" value="Envoyer" className={"btn btn-primary mt-5"} />

						</div>


					</form>
					
					
				</div>
			</div>
	)
}

export default ChangeOwnerOverlay;