import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faSave, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { formToObject } from "../../../Utils/Helpers";
import { connect } from "react-redux";
import Select from "react-select";
import CoSiteRow from "./CoStructureRow";
import Restricted from "../../../Utils/Restricted";
import PlanPmOverlay from "../SiteList/PlanPmOverlay";

class CoSiteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cosites: [],
      loading: true,
      showCmOverlay: false,
      selectedSubSite: {},
      agents: [],
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.loadCoSites();
    api
      .get("/api/sites/filters")
      .then((response) => {
        if (response.data.success) {
          
          if(this._isMounted)
            this.setState({ agents: response.data.agents });

        }
      });
  }

  loadCoSites = () => {
    api.get(`/api/structures/costructures/${this.props.siteid}`).then(res => {
      this.setState({ cosites: res.data.data, loading: false, addEnabled: false });
    });
  };

  toggleAddCoSite = () => {
    this.setState({ addEnabled: true });
  };

  submitNewSite = e => {
    e.preventDefault();

    const data = formToObject(e.target);

    this.setState({ loading: true });
    api.post(`/api/structures/costructures/${this.props.siteid}/add`, data).then(res => {
      this.loadCoSites();
    });
  };

  planCM = (site) => {
    this.setState({ 
      showCmOverlay: true, 
      selectedSubSite: site 
    })
  }

  setLoading = loadingState => this.setState({ loading: loadingState });

  render() {
    const { cosites, showCmOverlay } = this.state;
    // console.log('this filter '+ this.props.filters.cosite_types)
    return (
      <>
        {showCmOverlay && (
          <PlanPmOverlay
            close={() => this.setState({ showCmOverlay: false })}
            loadSites={this.loadCoSites}
            open={true}
            site={this.props.site}
            siteid={this.state.selectedSubSite.id}
            subsite={this.state.selectedSubSite}
            taskType={"cm"}
            {...this.props}
          />
        )}
        <h3>
         Sous Structure de la direction
          {this.state.loading && <FontAwesomeIcon icon={faSpinner} className={"ml-2"} style={{ fontSize: 16 }} spin />}
          <Restricted scope={"pm"} permission={"edit_site"}>
            <button type="button" className={"btn btn-sm btn-primary float-right"} onClick={this.toggleAddCoSite}>
              Ajouter Nouveau
            </button>
          </Restricted>
          
        </h3>

        <div className={"ctable"}>
          <div className={"row head-titles ctable-row"}>
            {/* <div className={"col-md-2"}>Promoteur</div> */}
            <div className={"col-md-3"}>Code</div>
            <div className={"col-md-3"}>Nom</div>
            {/* <div className={"col-md-2"}>Type de technologie</div> */}
            {/* <div className={"col-md-4"}>Agent</div> */}
          </div>
          {this.state.addEnabled && (
            <form onSubmit={this.submitNewSite}>
              <div className={"row ctable-row"}>
                {/* <div className={"col-md-2"}>
                  <Select
                    className={"col-md-12"}
                    options={this.props.filters.cosite_types}
                    placeholder={"Type..."}
                    classNamePrefix={"custom-select"}
                    name={"site_type"}
                  />
                </div> */}
              <div className={"col-md-3"}>
                  <input name={"site_code"} placeholder={"Code"} type="text" className={"form-control"} />
                </div>
                <div className={"col-md-3"}>
                  <input name={"site_name"} placeholder={"Noms "} type="text" className={"form-control"} />
                </div>  

                
                <div className={"col-md-2"}>
                  <button type="submit" className={"task-action-btn"}>
                    Ajouter <FontAwesomeIcon icon={faSave} />{" "}
                  </button>
                </div>
              </div>
            </form>
          )}
          {cosites &&
            cosites.map((item, key) => (
              <CoSiteRow key={"row_" + key} setLoading={this.setLoading} loadCoSites={this.loadCoSites} item={item}  planCM={this.planCM} />
            ))}

        
        </div>
      </>
    );
  }
}

export default connect(state => ({ filters: state.filters }))(CoSiteList);
