import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFileExport, faEdit } from "@fortawesome/free-solid-svg-icons";

import api from "../../../../Utils/Api";
import PhotoItem from "../../../../Utils/PhotoItem";
import { convertDMSToDD } from "../../../../Utils/Helpers";
import { isJsonString } from "../../../../Utils/Helpers";
import Modal from "react-bootstrap/Modal";

import ItemEditOverlay from "../../components/ItemEditOverlayTache";
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
import "leaflet/dist/images/marker-shadow.png";
import ReactLeafletKml from "react-leaflet-kml";
import Constants from "../../../../Utils/Constants";
import { useSelector } from "react-redux";
import Restricted from "../../../../Utils/Restricted";

const moment = require("moment");

const capacity = Array(30).fill(0);
const countC = [3, 7, 7, 9, 9];

const SubSite = ({
  data,
  active,
  geo,
  onchange,
  id,
  task,
  code,
  cosite,
  loadtasks,
  ...props
}) => {
  const { session } = useSelector((state) => ({ session: state.session }));
  const [show, setShow] = useState(false);
  const [selectedTable, setSelectedTable] = useState("");
  const [count, setCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const exportForm = useRef(null);
  const detailInput = useRef(null);
  const [editing, setEditing] = useState(false);
  const [fieldEdit, setFieldEdit] = useState("");
  const [showItemEditOverlay, setShowItemEditOverlay] = useState(false);
  const [selected_pm_edit, setSelected_pm_edit] = useState({});
  const [dataload, setData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  const toggleItemEditOverlay = () => {
    setShowItemEditOverlay(!showItemEditOverlay);
  };

  const editTask = (selected_pm_edit, item) => {
    setSelected_pm_edit(selected_pm_edit);
    setShowItemEditOverlay(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const exportPMBatXLSX = () => {};
  const exportInventairePhysique = (cosite) => {
    console.log("export exportPreInventaire", cosite, task);
    exportForm.current.action =
      Constants.apiEndPoints.EXPORT_INVENTAIRE_PHYSIQUE(
        task.key,
        cosite.site_id
      );
    exportForm.current.submit();
    console.log(exportForm.current);
  };

  const exportInventaire = (cosite) => {
    console.log("export exportPreInventaire", cosite, task);
    exportForm.current.action = Constants.apiEndPoints.EXPORT_INVENTAIRE(
      task.key,
      cosite.site_id
    );
    exportForm.current.submit();
    console.log(exportForm.current);
  };

  const exportPreInventaire = (cosite) => {
    console.log("export exportPreInventaire", cosite, task);
    exportForm.current.action = Constants.apiEndPoints.EXPORT_PRE_INVENTAIRE(
      task.key,
      cosite.site_id
    );
    exportForm.current.submit();
    console.log(exportForm.current);
  };

  const exportDesBiens = (task, cosite) => {
    exportForm.current.action = Constants.apiEndPoints.EXPORT_BIENS(
      cosite.site_parent,
      task.key
    );
    exportForm.current.submit();
    console.log(exportForm.current);
  };

  const enableEditing = (e, item) => {
    setEditing(true);
    setShowItemEditOverlay(true);
    setLoading(false);
    setSelected_pm_edit(item);
  };

  return (
    <>
      <form method="post" ref={exportForm} target={"_blank"}>
        <input type="hidden" name={"pm"} ref={detailInput} />
        <input type="hidden" name={"token"} value={session.user.token} />
      </form>
      <Restricted scope={"pm"} permission={"export_task"}>
        <button
          type="button"
          className={"btn btn-sm btn-primary ml-2"}
          onClick={() => exportInventairePhysique(cosite)}
        >
          <FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} />
          Export Fiche Local
        </button>
      </Restricted>

      <Restricted scope={"pm"} permission={"export_task"}>
        <button
          type="button"
          className={"btn btn-sm btn-primary ml-2"}
          onClick={() => exportInventaire(cosite)}
        >
          <FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} />
          Export Fiche Inventaire
        </button>
      </Restricted>

      <Restricted scope={"pm"} permission={"export_task"}>
        <button
          type="button"
          className={"btn btn-sm btn-primary ml-2"}
          onClick={() => exportPreInventaire(cosite)}
        >
          <FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} />
          Export Fiche Pre-Inventaire
        </button>
      </Restricted>

      <Restricted scope={"pm"} permission={"export_task"}>
        <button
          type="button"
          className={"btn btn-sm btn-primary ml-2"}
          onClick={() => exportDesBiens(task, cosite)}
        >
          <FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} />
          Export Fiche Recenoment physique des biens
        </button>
      </Restricted>

      <h6>Informations générales (Fiche Technique) </h6>
      <div className={"row col-md-6"}>
        <label>Date de début de la tâche : </label>
        {moment(task.created_at).format("YYYY-MM-DD")}
      </div>

      <div className={"row col-md-6"}>
        <label>Heure de début de la tâche : </label>
        {moment(task.created_at).format("HH:mm:ss")}
      </div>

      <div className={"row col-md-6"}>
        <label>Date de fin de la tâche : </label>
        {moment(data.finished_at).format("YYYY-MM-DD")}
      </div>

      <div className={"row col-md-6"}>
        <label>Heure de fin de la tâche : </label>
        {moment(data.finished_at).format("HH:mm:ss")}
      </div>

      <label> Items : </label>
      {data.Item.length > 0 && (
        <div className={"col-md-12"}>
          <div className="row border">
            <div className="col-md-2">
              <b>Nom </b>
            </div>
            <div className="col-md-2">
              <b>Numéro Invnetaire </b>
            </div>
            {/*  <div className="col-md-2"><b>Numéro Comptable</b></div> */}
            <div className="col-md-1">
              <b>Etat</b>
            </div>
            <div className="col-md-1">
              <b>valeur</b>
            </div>
            <div className="col-md-2">
              <b>Photos</b>
            </div>
            <div className="col-md-2">
              <b>Structure</b>
            </div>
            <div className="col-md-1">
              <b>Sous Structure</b>
            </div>
            <div className="col-md-1">
              <b>modifier</b>
            </div>
          </div>

          {data.Item.map((item) => (
            <div className="row border">
              <div className="col-md-2">
                {item.name_item ? item.name_item : ""}
              </div>
              <div className="col-md-2">
                {item.code_item ? item.code_item : "-"}
              </div>
              {/*   <div className="col-md-2">{item.nbr_compt ? item.nbr_compt : '-' }</div> */}
              <div className="col-md-1">
                {item.etat_item ? item.etat_item : "-"}
              </div>
              <div className="col-md-1">
                {item.val_item ? item.val_item : "-"}
              </div>

              <div className="col-md-2">
                {data.photos && data.photos.length > 0
                  ? data.photos.map((photoItem) =>
                      item.code_item == photoItem["index"] ? (
                        <PhotoItem photo={photoItem} />
                      ) : (
                        <></>
                      )
                    )
                  : "/"}
              </div>
              <div className="col-md-1">
                {cosite.site_name ? cosite.site_name : "-"}
              </div>
              <div className="col-md-2">
                {task.costructures.site_name ? task.costructures.site_name : ""}
              </div>

              <div className="col-md-1">
                <>
                  <button
                    name="Mitigation Possible"
                    onClick={(e) => enableEditing(e, item)}
                    className="task-action-btn"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    Modifier
                  </button>
                  {editing && (
                        <ItemEditOverlay
                        open={showItemEditOverlay}
                        task={selected_pm_edit}
                        structure={task.costructures.site_name}
                        cosite={cosite.site_name}
                        taskId={task}
                        item_cod_dr={task.task_site_code}
                        toggle={toggleItemEditOverlay}
                        data={data}
                        editTask={editTask}
                        item_s_stru={cosite.site_id}
                        item_stru={cosite.site_parent}
                        structurecode={task.costructures}
                        reload={loadtasks}
                        id={id}
                         
                      />
                  )}
                </>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SubSite;
