import React, { useState } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import api from "../../Utils/Api";
import * as helpers from "../../Utils/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { formToObject } from "../../Utils/Helpers";
import { useDispatch } from "react-redux";
import {
  startLoading,
  setFlash,
  doneLoading,
  setOverlay,
} from "../../Redux/actions";
import { Trans } from "react-i18next";
import { Multiselect } from "multiselect-react-dropdown";
import WindowedSelect from "react-windowed-select";
import { components, createFilter } from "react-windowed-select";
import { Redirect } from "react-router-dom";
import HomeView from "../Home/HomeView";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import DeliveryRouter from "../../Routers/DeliveryRouter";
import SSRRouter from "../../Routers/SSRRouter";
import FlashMessage from "react-flash-message";
import { get } from "jquery";
import { flashMessage } from "../../Redux/reducers/UI";
import Success from "./sucsess";
import Failure from "./failur";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";

const moment = require("moment");

const mapStateToProps = (state) => {
  return {
    // auth: state.session.auth,
    user: state.session.user,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    flash: (d) => dispatch(setFlash(d)),
    setOverlay: (e) => dispatch(setOverlay(e)),
  };
};

const customFilter = createFilter({ ignoreAccents: false });

const customComponents = {
  ClearIndicator: (props) => (
    <components.ClearIndicator {...props}>clear</components.ClearIndicator>
  ),
};

export class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sites: [],
      cosites: [],
      cositesSelect: [],
      costructures: [], //for sous structure
      selectedOptions: [],
      planning_date: moment(new Date()).format("YYYY-MM-DD"),
      selectedValue: "",
      selectedStructure: "", //selected value for structure to get sous structure
      showMessage: false,
      showMessage2: false,
      siteName: { label: "", value: "" },
      isLoaded: false,
      siteid: "",
      filters: {},
      agents: [],
      alert_message: "",
      types: [],
      types_value: "",
      selected_type: "ssr",
      files: [],
      file_id: "",
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeStructure = this.handleChangeStructure.bind(this); //change structure to get the sous structure
    this.handleChangeSelected = this.handleChangeSelected.bind(this);
    this.handleChangeSite = this.handleChangeSite.bind(this);
    this.handleChangeSector = this.handleChangeSector.bind(this);

    this.setAgent = this.setAgent.bind(this);
    this.setTeam = this.setTeam.bind(this);
    this.changeCMCode = this.changeCMCode.bind(this);
    this.sitesArray = [];
  }

  changeSub(e) {
    this.setState({
      info: {
        ...this.state.info,
        subscribe: e.value,
      },
    });
  }
  handleChangeSelected(event) {
    this.setState({ selected_type: event.target.value });
  }
  formSubmit = (e) => {
    e.preventDefault();
    this.setState({ showMessage: false });
    this.setState({ showMessage2: false });

    let that = this;

    const data = helpers.formToObject(e.target);
    console.log("formSubmit ===>", data);
    api.post("/api/planning/assign", data).then((response) => {
      if (response.data.success) {
        this.setState({ alert_message: "success" });
      } else {
        this.setState({ alert_message: "Error" });
      }
    });
  };

  handleChange(event) {
    const selectedValue = event.target.value;

    this.setState({ selectedValue: event.target.value });
  }
  handleChangeStructure(selected) {
    console.log("handl change structuere", selected);
    //  const selectedStructure = event.target.value;
    // console.log(' selectedStructure:', selectedStructure)
    //this.setState({selectedStructure: selectedStructure})
    this.setState({ selectedOptions: selected });
  }

  onSelectSite(selectedList, selectedItem) {
    this.sitesArray.push(selectedItem);
  }
  handleChangeSite(e) {
    console.log("handle change sites", e.target.sites);
    // let cmImpactedSite = this.state.cmImpactedSite
    this.setState({ sites: e.target.sites }, () => {
      console.log("hello handle change", this.state.sites);
    });
  }
  handleChangeSector(e) {
    let cmImpactedSector = this.state.cmImpactedSector;
    this.setState({ cmImpactedSector: !cmImpactedSector });
  }

  setAgent(e) {
    var sitePMAgentOwner = { label: e.label, value: e.value };
    this.setState({ sitePMAgentOwner: sitePMAgentOwner });
  }

  setTeam(e) {
    var siteTeam = { label: e.label, value: e.value };
    this.setState({ siteTeam: siteTeam });
  }
  changeCMCode(e) {
    var parent = e.label;
    var code = this.props.filters.pm_code.map((obj) => {
      return String(obj.parent_label).toUpperCase() ===
        String(parent).toUpperCase() ? (
        { label: obj.label, value: obj.value }
      ) : (
        <></>
      );
    });
    this.setState({ pmCode: code }, () => {
      console.log("hello state", this.state.pmCode);
    });
  }

  componentDidMount() {
    api.get("/api/sites/filters").then((response) => {
      if (response.data.success) {
        this.setState({ agents: response.data.agents });
      }
    });
    api
      .get("/api/sites/fetch/filter?limit=-1") //this for filter sites
      .then((response) => {
        if (response.data.success) {
          this.setState({ sites: response.data.sites });
          console.log("sites===>", Object.keys(response.data.sites[0]));
        }
      });
    api.get("/api/task_type/fetch").then((response) => {
      if (response.data.success) {
        this.setState({
          types: response.data.tasks,
        });

        // console.log("hello tasks /api/task_type/fetch ", response.data.tasks);
      }
    });
    console.log("componentDidMount", this.state.selectedValue);

    /*  api
      .get(`api/sites/cosites/${this.state.selectedValue}`)
      .then((response) => {
        if (response.data.success) {
          this.setState({ cosites: response.data.data });
          this.fetchCostructures();
          //map((item) => ({ value: item, label: item }));
        }
      }); */

    /*   api
      .get(`/api/cosites/costructures/${this.state.selectedStructure}`)
      .then((response) => {
        console.log("api sous structure");
        if (response.data.success) {
          this.setState({ costructures: response.data.data });
        }
      }); */
  }

  fetchCostructures = () => {
    api
      .get(`/api/cosites/costructures/${this.state.selectedStructure}`)
      .then((response) => {
        if (response.data.success) {
          this.setState({ costructures: response.data.data });
        }
      })
      .catch((error) => {
        // Handle any errors from the second API call
        console.error("Error fetching costructures:", error);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedValue == "") {
      if (this.state.sites[0] != null) {
        this.setState({ selectedValue: this.state.sites[0].site_id });
      }
    }
    if (prevState.selectedValue !== this.state.selectedValue) {
      // Perform action when the value state changes
      api
        .get(`api/sites/cosites/${this.state.selectedValue}`)
        .then((response) => {
          if (response.data.success) {
            this.setState({ cosites: response.data.data });
            const all = { value: "1", label: "ALL" };
            const options = response.data.data.map((item) => ({
              value: item.id,
              label: item.site_name,
            }));
            options.unshift(all);

            console.log("options", options, all);
            this.setState({ cositesSelect: options });
          }
        });
    }
    if (this.state.selectedStructure == "") {
      console.log("this.state.cosites[0]", this.state.cosites[0]);
      if (this.state.cosites[0] != null) {
        console.log("this.state.cosites[0] not null", this.state.cosites[0]);
        this.setState({ selectedStructure: this.state.cosites[0].id });
      }
    }
    if (prevState.selectedStructure !== this.state.selectedStructure) {
      // Perform action when the value state changes
      api
        .get(`/api/cosites/costructures/${this.state.selectedStructure}`)
        .then((response) => {
          //console.log('api cose===>',this.state.selectedValue)
          if (response.data.success) {
            //console.log("seeee===>",this.state.selectedValue)
            this.setState({ costructures: response.data.data });
          }
        });
    }
  }

  uploadFile = ({ target: { files } }) => {
    console.log("files", files[0]);
    let data = new FormData();

    data.append("file", files[0]);
    console.log("data files", data);
    api.post("/api/settings/files/new", data).then((response) => {
      console.log("data response", response);

      if (response.data.success) {
        //console.log('file_id',this.state.file_id);
        this.setState({ file_id: response.data.file_id });
      }
    });
    //   api.get("/api/settings/files").then((response)=>{console.log('files last',response);
    //        this.setState({files:response.data.files});
    //        console.log('files stqtes',this.state.files);
    //        let last_id=this.state.files.pop();
    //        console.log('last_id',last_id);
    //       this.setState({file_id:last_id.id});
    //       console.log('file_id',this.state.file_id);

    // });
    // api.post("/api/settings/files/new",data).then(res=>{console.log('resultat file',res)});
    // this.state.files=
  };

  render() {
    //console.log('this.state.sites===>', this.state.sites[0],this.state.cosites)

    if (this.state.alert_message === "success") {
      return <Success />;
    } else if (this.state.alert_message === "Error") {
      return <Failure />;
    } else {
      const fileid = this.state.file_id;

      return (
        <form onSubmit={this.formSubmit}>
          {/* <h5>Planifier une tâche</h5> */}
          {/* */}
          <div className="form-group">
            <h4 className={"mb-4"}>
              <Trans i18nKey="pm_information" />
            </h4>
            <input type="hidden" name={"task_type"} value={"inventory"} />
          </div>
          {this.state.selected_type === "installation" && (
            <div className={"form-group"}>
              <label className={"font-exp"}>Type Installation </label>

              <select name={"installation_type"} className={"form-control"}>
                <option key="NOUVEAU" value="nouveau">
                  nouveau site
                </option>
                <option key="MODERNISATION" value=">modernisation">
                  modernisation d'un site
                </option>
                <option key="MW" value="mw">
                  mw
                </option>
              </select>
            </div>
          )}

          {this.state.selected_type === "delivery" && (
            <div className={"form-group mt-3"}>
              <label className=" font-exp">PickUp site: </label>
              <select name={"pickup_site"} className={"form-control"}>
                {this.state.sites.map((site) => (
                  <option key={site.site_id} value={site.site_id}>
                    {site.site_name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className={"form-group mt-3"}>
            <label className={"font-exp"}>Direction *</label>

            <select
              name={"drop_site"}
              className={"form-control"}
              onChange={this.handleChange}
            >
              {this.state.sites.map((site) => (
                <option key={site.site_id} value={site.site_id}>
                  {site.site_name}
                </option>
              ))}
            </select>
          </div>
          {this.state.selected_type === "delivery" && (
            <div className={"form-group mt-3"}>
              <label className={"font-exp"}>BOQ</label>
              <input type="hidden" name={"file_id"} value={fileid} />
              <input
                type="file"
                className="form-control delivery-file-uploder"
                onChange={this.uploadFile}
              />
            </div>
          )}

          <div className={"form-group mt-3"}>
            <label className={"font-exp"}>Structure *</label>
            {/*    <select 
            name={"site_structure"}
            className={"form-control"} 
            isMulti
            
            options={this.state.cosites}
            onChange={this.handleChangeStructure}>
             
 {this.state.cosites.map((cosite) => 
                                      
                                      <option key={cosite.id} value={cosite.id}>{cosite.site_name}</option>
                                  
                              )}

 </select>  */}

            <Select
              isMulti
              options={this.state.cositesSelect}
              value={this.state.selectedOptions}
              onChange={this.handleChangeStructure}
              className="basic-multi-select"
              classNamePrefix="select"
            />

            <input
              type="hidden"
              name="site_structure"
              value={this.state.selectedOptions
                .map((option) => option.value)
                .join("/")}
            />
          </div>

          {/* <div className={"form-group mt-3"} >
            <label className={"font-exp"} >Sous Structure *</label>
            <select name={"site_sous_structure"} className={"form-control"}  >

            {this.state.costructures.map((costructure) => 
            
            <option key={costructure.id} value={costructure.id}>{costructure.site_name}</option>
        
    )} 
               
           
            </select>
           
          </div> */}

          <div className="form-group mt-3">
            <label className={"font-exp"} id="ag">
              Sélectionner un agent *
            </label>
            <div>
              <Select
                classNamePrefix={"custom-select"}
                name={"agent"}
                options={this.state.agents}
                required
                cacheOptions
                defaultOptions
                placeholder="Agent"
                className={"d-block mt-1"}
                onChange={this.setAgent}
              />
            </div>
          </div>

          <div className={"form-group mt-3"}>
            <label className={"font-exp"}>Date de planification *</label>
            <input
              id="date"
              required
              // defaultValue={site.site_next_pm}
              name={"planning_date"}
              type={"date"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label>Description</label>
            <textarea
              name="instruction"
              className={"form-control"}
              id="description"
            />
          </div>

          <div className="form-group mt-3">
            <input
              type="submit"
              value="Planifier"
              className={"btn btn-primary btn-sm mt-2"}
            />
          </div>
        </form>
      );
    }
  }
}
// export default Form;
export default connect(mapStateToProps, mapDispatchToProps)(Form);
