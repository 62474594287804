import http from "./Api";
import Constants from "./Constants";

const loginCall = (data) => http.post(Constants.apiEndPoints.CONNECT, data);
const fetchTasks = (params) => http.get();
const fetchTaskDetail = (id) =>
  http.get(Constants.apiEndPoints.FETCH_TASK_DETAIL(id));

const fetchSSRTaskDetail = (id) =>
  http.get(Constants.apiEndPoints.FETCH_SSR_TASK_DETAIL(id));
const fetchInventoryTaskDetail = (id) =>
  http.get(Constants.apiEndPoints.FETCH_IVENTORY_TASK_DETAIL(id));

const fetchInventoryItemDetail = (id) =>
  http.get(Constants.apiEndPoints.FETCH_IVENTORY_ITEM_DETAIL(id));

const fetchInventoryTaskAgentDetail = (id) =>
  http.get(Constants.apiEndPoints.FETCH_IVENTORY_TASK_AGENT_DETAIL(id));

const fetchFilters = () => http.get(Constants.apiEndPoints.FETCH_FILTERS);
const fetchNotifications = () =>
  http.get(Constants.apiEndPoints.FETCH_NOTIFICATIONS);

const getExportTMDB = (id) => http.get(Constants.apiEndPoints.EXPORT_TMDB);

const closeTask = (taskid) => http.post("/api/pm/close/" + taskid);
const returnTask = (data) => http.post("/api/pm/return", data);
const acceptTask = (taskid) => http.post("/api/pm/accept/" + taskid);
const acceptEndInventaire = (taskid) =>
  http.post("/api/pm/change_inventaire/" + taskid);
//const rejectTask = (taskid) => http.post('/api/pm/reject/'+ taskid);
const rejectTask = (data) => http.post("/api/pm/reject", data);
const addDataTask = (data) => http.post("/api/pm/addData", data);

const replanTask = (data) => http.post("/api/task/edit", data);
const editTest = (data) => http.post("/api/test/edit", data);

export default {
  fetchTaskDetail,
  fetchSSRTaskDetail,
  fetchInventoryTaskDetail,
  fetchInventoryItemDetail,
  fetchInventoryTaskAgentDetail,
  returnTask,
  closeTask,
  loginCall,
  fetchTasks,
  fetchFilters,
  fetchNotifications,
  acceptTask,
  rejectTask,
  addDataTask,
  replanTask,
  editTest,
};
