import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";

class TaskNew extends Component {
  
  submitTask = (e) => {
    e.preventDefault();
    this.props.submitTask(e);
  }

  render() {
    return (
      <React.Fragment>
        <div className={"container-header"}>Nouvelle Direction</div>

        <form onSubmit={this.submitTask}>
          <div className={"row"}>
            <div className={"col-md-6 taskForm"}>

			        <div className={"form-group"}>
                <label>Direction Owner *</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_owner"
                  defaultValue={{ label: "SAA", value: "saa" }}
                  options={this.props.filters.site_owners.filter(item => item.value !== "outsource" && item.value !== "")}
                />
              </div>

              <div className={"form-group"} style={{display: 'none'}}>
                <label>Type Direction</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="type_site"
                  defaultValue={{ label: "site", value: 'site' }}
                  options={[
                    {value : 'site', label: 'site'}, 
                    {value : 'entrepot', label:'entrepot'},
                  ]}
                />
              </div>

              {/* <div className={"form-group"}>
                <label><b>DOT *</b></label>
                <input
				          required
                  type="text"
                  name="dot"
                  className={"form-control"}
                />
              </div>

              <div className={"form-group"}>
                <label><b>CMP *</b></label>
                <input
				          required
                  type="text"
                  name="cmp"
                  className={"form-control"}
                />
              </div>

              <div className={"form-group"}>
                <label>Type OLT</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="type_olt"
                  defaultValue={{ label: "X2", value: 'X2' }}
                  options={[
                    {value : 'X2', label: 'X2'}, 
                    {value : 'X7', label:'X7'},
                    {value : 'X17', label:'X17'},
                  ]}
                />
              </div> */}

			        <div className={"form-group"}>
                <label><b>Code Direction*</b></label>
                <input
				          required
                  type="text"
                  name="site_code"
                  className={"form-control"}
                />
              </div>

            

 

              <div className={"form-group"}>
                <label>Nom Direction *</label>
                <input
                  type="text"
                  name="site_name"
                  className={"form-control"}
                />
              </div>

              
              <div className={"form-group"}>
                <label>Wilaya *</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_address_wilaya"
                  options={this.props.filters.wilayas}
                />
              </div>

              <div className={"form-group"}>
                <label>Adresse du Direction *</label>
                <input
                  type="text"
                  name="site_addess_info"
                  className={"form-control"}
                />
              </div>

              <div className={"form-group"}>
                <button
                  type="submit"
                  className={"btn btn-primary float-right"}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({ filters: state.filters }))(TaskNew);
