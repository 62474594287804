import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import {
  setAuthAction,
  startLoading,
  doneLoading,
  closeFlash,
  setFlash,
  setOverlay,
} from "../../Redux/actions/index";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../Utils/Api";
import Pagination from "../../Utils/Pagination";
import SitesExport from "../../Utils/SitesExport";
import SiteRow from "./SiteList/SiteRow";
import EditSiteOverlay from "./SiteList/EditSiteOverlay";
import AssignSiteOverlay from "./SiteList/AssignSiteOverlay";
import PlanPmOverlay from "./SiteList/PlanPmOverlay";
import ChangeOwnerOverlay from "./SiteList/ChangeOwnerOverlay";
import DeleteSiteOverlay from "./SiteList/DeleteSiteOverlay";
import http from "../../Utils/Api.js";
const moment = require("moment");

const mapStateToProps = (state) => {
  return {
    auth: state.session.auth,
    user: state.session.user,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAuth: (user) => dispatch(setAuthAction(user)),
  setOverlay: (e) => dispatch(setOverlay(e)),
  doneLoading: () => dispatch(doneLoading()),
  isLoading: (p) => dispatch(startLoading(p)),
  setFlash: (f) => dispatch(setFlash(f)),
  closeFlash: (f) => dispatch(closeFlash(f)),
  dispatchOverlay: (e) => dispatch(setOverlay(e)),
});

class TaskList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      showSiteOverlay: false,
      showOverlay: false,
      site: "",
      agentid: "",
      date: "",
      selected_site: {},
      showDeletePlanOverlay: false,
      select_site: 0,
    };

    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  handleEscape = (e) => {
    if (e.keyCode === 27) {
      this.setState({ showOverlay: false });
    }
  };

  toggleOverlay(e) {
    this.setState({ showOverlay: !this.state.showOverlay });
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handleEscape);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEscape);
  }

  planSite = (site, e) => {
    this.setState({
      taskType: "delivery",
      date: site.site_next_pm ? site.site_next_pm : "",
      agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
      agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
      siteid: site.site_id,
      site: site,
      pickup_site: site,
      delivery_site: site,
      showPlanOverlay: true,
    });
  };

  assignSite = (site, e) => {
    this.setState({
      agentid: site.site_next_pm_agent_id ? site.site_next_pm_agent_id : "",
      agent_username: site.site_next_pm_agent ? site.site_next_pm_agent : "",
      siteid: site.site_id,
      site: site,
      showAssignOverlay: true,
    });
  };

  ownerSite = (site, e) => {
    this.setState({
      defaultOwner: site.owner ? site.owner : "",
      site: site,
      showOwnerOverlay: true,
    });
  };
  deleteSite = (site, e) => {
    console.log("site to be removed ====>", site, e);
    this.setState({
      showDeleteOverlay: true,
      defaultOwner: site.owner ? site.owner : "",
      site: site,
    });
  };

  editSite = (site) => {
    this.setState({
      showSiteOverlay: true,
      selected_site: site,
    });
  };
  changeInventaire = (site) => {
    this.props.isLoading({ message: "Mise a jour en cours." });
    console.log("change inventaire ===>", site.site_id);

    /*   api.post(`/api/sites/change_inventaire/${site.site_id}`).then((res) => {
      console.log("res===>", res.data);
      this.props.handlers.loadSites();
      this.props.doneLoading();
    }); */

    /* api.post(`/api/pm/change_inventaire/${site.site_id}`).then((response) => {
      console.log("response api==>",response)
      if (response.data.success) {
        this.props.dispatchOverlay({
          message: "Site attribué avec succés",
          label: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
        });

        this.props.handlers.loadSites();
        this.props.close();
      } else {
        this.props.setFlash({
          active: true,
          message:
            "Une erreur est survenue, veuillez contacter l'administrateur.",
          classes: "warning",
          style: {},
        });
      }

      this.props.doneLoading();
    }); */
  };
  toggleDeleteOverlay = (siteId) =>
    this.setState({
      select_site: siteId,

      showDeletePlanOverlay: !this.state.showDeletePlanOverlay,
    });

  render() {
    //const { t, i18n } = useTranslation();
    const data = this.props.data;
    const {
      showAssignOverlay,
      showPlanOverlay,
      showOwnerOverlay,
      showDeleteOverlay,
    } = this.state;

    return (
      <React.Fragment>
        <div className="col-md-12 ctable mb-3">
          <div className={"d-none d-sm-block"}>
            <div className={"row head-titles ctable-row"}>
              <div className={"col-md-1"}>Code</div>
              <div className={"col-md-2"}>Nom</div>
              <div className={"col-md-1"}>Wilaya</div>
              <div className={"col-md-2"}>
                {this.props.user.role === "admin" ||
                this.props.user.role === "cluster_manager"
                  ? "Owner"
                  : "Derniere Delivery"}
              </div>
              <div className={"col-md-1"}>Type</div>
              <div className={"col-md-3"}>inventaire</div>
              {/*    <div className={"col-md-1"}>Ingénieur</div> */}
              <div className={"col-md-2 task-details-col"}>Action</div>
            </div>
          </div>

          {data.sites.map((site, i) => (
            <SiteRow
              role={this.props.user.role}
              key={i}
              site={site}
              selectSite={this.props.handlers.selectSite}
              editSite={this.editSite}
              changeInventaire={this.changeInventaire}
              reassignSite={this.assignSite}
              planSite={this.planSite}
              ownerSite={this.ownerSite}
              //add delete site to site row
              deleteSite={this.deleteSite}
              session={this.props.user}
            />
          ))}
          {/*    </tbody>*/}
          {/*  </table>
          </div>*/}
        </div>

        <div className="col-md-12 ctable mb-3">
          {data.sites.length === 0 && (
            <div className={"col-md-12 text-center my-3"}>
              Aucune Direction trouvé
            </div>
          )}

          {showAssignOverlay && (
            <AssignSiteOverlay
              close={() => this.setState({ showAssignOverlay: false })}
              loadSites={this.props.handlers.loadSites}
              open={true}
              site={this.state.site}
              {...this.props}
            />
          )}

          {showPlanOverlay && (
            <PlanPmOverlay
              close={() => this.setState({ showPlanOverlay: false })}
              loadSites={this.props.handlers.loadSites}
              open={true}
              site={this.state.site}
              taskType={this.state.taskType}
              {...this.props}
            />
          )}

          {showOwnerOverlay && (
            <ChangeOwnerOverlay
              loadSites={this.props.handlers.loadSites}
              open={true}
              site={this.state.site}
              close={() => this.setState({ showOwnerOverlay: false })}
            />
          )}

          {showDeleteOverlay && (
            <DeleteSiteOverlay
              loadSites={this.props.handlers.loadSites}
              open={true}
              site={this.state.site}
              close={() => this.setState({ showDeleteOverlay: false })}
              {...this.props}
            />
          )}

          <EditSiteOverlay
            loadSites={this.props.handlers.loadSites}
            open={this.state.showSiteOverlay}
            site={this.state.selected_site}
            close={() =>
              this.setState({ showSiteOverlay: false, selected_site: {} })
            }
            {...this.props}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
