import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import api from "../../../Utils/Api"; 
import Restricted from "../../../Utils/Restricted";
 
const ItemEditOverlay = ({toggle, open, task, reload, scope = "pm", ...props
   
}) => { 
   console.log('task from edit====================> ',task)

    
     
  const submitSiteForm = (e) => {
    props.isLoading({
      message: "Mise à jour en cours",
      animated: true,
    });

    e.preventDefault();

    const formdata = new FormData(e.target);

    let data = {};
    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = props.user;

    toggle();

    api.post("/api/item/edit/" + task.item_code, data).then((res) => {
       reload();
     
    });
  };

 

  return (
    <>
       

      <div className={"ui-overlay" + (open ? " open" : "")}>
        <div className={"ui-overlay-content anomalie-history col-md-6"}>
          <button
            onClick={toggle}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5>Modifier Item </h5>

          <form onSubmit={submitSiteForm}>
            {open && (
              <>
                {/* <input type="hidden" name="userid" value={this.props.user.userid} /> */}
 
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Nom Item </label>
                  <input
                    type="text"
                    name="item_name"
                    className={"form-control col-md-8"}
                      defaultValue={task.item_name}  
                  />
                </div>

                
                
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Num Inventaire</label>
                  <input
                    type="item_code"
                    disabled={true}
                    className={"form-control col-md-8"}
                   defaultValue={task.item_code}  
                  />
                </div>

               
                
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Etat</label>
                  <input
                    type="text"
                    name="item_etat"
                    className={"form-control col-md-8"}
                    defaultValue={task.item_etat}  
                  />
                </div>
              
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Code </label>
                  <input
                    type="text"
                    name="item_id"
                    className={"form-control col-md-8"}
                    defaultValue={task.item_id}  
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Code Structure</label>
                  <input
                    type="text"
                    name="item_stru"
                    className={"form-control col-md-8"}
                    defaultValue={task.item_stru}  
                  />
                </div>

              

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Code Sous Structure</label>
                  <input
                    type="text"
                    name="item_s_stru"
                    className={"form-control col-md-8"}
                   defaultValue={task.item_s_stru}
                     
                  />
                </div>
               

                
             

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <input
                    type="submit"
                    className={"btn btn-primary col-md-12"}
                    value="Valider les modifications"
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ItemEditOverlay;
