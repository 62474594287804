import React, { Component } from "react";
import set from "lodash/set";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import TaskList from "../../Components/Task/Item/ItemTaskList";
import {
  setAuthAction,
  startLoading,
  doneLoading,
  setOverlay,
} from "../../Redux/actions/index";
import Select from "react-select";
import SocketContext from "../../Utils/socket-context";
import api from "../../Utils/Api";
import Pagination from "../../Utils/Pagination";
import Constants from "../../Utils/Constants";
import { buildFilters } from "../../Utils/Helpers";
import Restricted from "../../Utils/Restricted";
import SelectFilter from "../../Utils/Filter/Elements/SelectFilter";
import ApiService from "../../Utils/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronCircleDown,
  faFileExport,
  faPlus,
  faFileExcel,
  faArrowUp,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";
import FilterElement from "../../Utils/Filter/FilterElement";
import FiltersWrap from "../../Utils/Filter/FiltersWrap";
import { formToObject } from "../../Utils/Helpers";
import SitesExport from "../../Utils/SitesExport";
import * as helpers from "../../Utils/Helpers";
const mapStateToProps = (state) => {
  return {
    session: state.session,
    filters: state.filters,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    setAuth: (e) => dispatch(setAuthAction(e)),
    setOverlay: (x) => dispatch(setOverlay(x)),
  };
}

class TaskListContainer extends Component {
  constructor(props) {
    super(props);

    this.props.isLoading({
      message: "Loading Items",
      animate: true,
    });

    this.state = {
      taskid: null,
      selectedValue: "",
      selectedStructure: "",
      showMessage: false,
      showMessage2: false,
      total: 0,
      showOverlay: false,
      showOverlayItem: false,
      sites: [],
      managers: [],
      tasks: [],
      agents: [],
      status: [],
      types: [],
      rows: [],
      item_sous_structure: [],
      item_structure: [],
      item_direction: [],
      cosites: [],
      costructures: [], //for sous structure
      current: 1,
      pages: 1,
      limit: 15,
      filters: {},
      showModal: false,
      activeOverlay: null,
      exportForm: null,
    };
    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadTasks: this.loadTasks.bind(this),
      updateFilter: this.updateFilter.bind(this),
      formSubmit: this.formSubmit.bind(this),
      formSubmithandle: this.formSubmithandle.bind(this),
      handleChange: this.handleChange.bind(this),
      handleChangeStructure: this.handleChangeStructure.bind(this),
    };
  }

  exportInventaire = (e) => {
    if (window.ga) window.ga("send", "event", "TaskList", "exportAnomalies");

    e.preventDefault();

    const url =
      Constants.apiURL + "/" + Constants.apiEndPoints.EXPORT_RAPPORT_INVENTAIRE;
    console.log("urll====>", url);
    e.target.action = url;
    e.target.submit();
  };

  toggleOverlay = (e) => {
    e.preventDefault();
    this.setState({ showOverlay: !this.state.showOverlay });
  };
  toggleOverlayItem = (e) => {
    e.preventDefault();
    this.setState({ showOverlayItem: !this.state.showOverlayItem });
  };
  handleChange = (e) => {
    e.preventDefault();

    this.setState({ selectedValue: e.target.value });
  };
  handleChangeStructure = (e) => {
    e.preventDefault();
    this.setState({ selectedStructure: e.target.value });
  };
  formSubmithandle = (e, success) => {
    e.preventDefault();
    this.setState({ error: "", notImported: [], loading: true });

    const data = new FormData(e.target);
    api
      .post(e.target.action, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.error) {
          this.setState({ error: res.data.error });
          this.setState({ alert_message: "Echec" });
          this.setState({ showOverlay: !this.state.showOverlay });
          this.props.setOverlay({
            message: res.data.error,
            label: "Echec",
            type: "Echec",
            button: {
              type: "close",
              label: "OK",
              link: "javascript:void(0)",
            },
          });
        }

        if (res.data.success) {
          this.setState({ showOverlay: true });
          this.setState({ error: "success" });
          this.setState({ alert_message: "success" });
          this.setState({ showOverlay: !this.state.showOverlay });
          //this.setState({ showOverlayItem: !this.state.showOverlayItem });
          this.props.setOverlay({
            message: "Opération effectué avec succés !",
            label: "success",
            type: "success",
            button: {
              type: "close",
              label: "OK",
              link: "javascript:void(0)",
            },
          });
        }
        if (res.data.notfound) {
          this.setState({ notImported: res.data.notfound });
          this.setState({ alert_message: "not found" });
          this.setState({ showOverlay: !this.state.showOverlayItem });
          /* this.props.setOverlay({
        
              message: res.data.notfound,
              label:"not found",
              type: "not found",
              button: {
                type: "close",
                label: "OK",
                link: "javascript:void(0)"
              },
              
            }); */
        }

        this.setState({ loading: false });
        this.props.doneLoading();
        this.setState({ showOverlayItem: !this.state.showOverlayItem });
      })
      .catch((err) => {
        this.props.doneLoading();
        this.props.setOverlay({
          message: "Une erreur est survenu",
          label: "not found",
          type: "not found",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
        });
        this.setState({ showOverlayItem: !this.state.showOverlayItem });
        this.setState({
          error: "Une erreur est survenu, veuillez contactez l'administrateur.",
          loading: false,
        });
      });
  };

  formSubmit = (e) => {
    e.preventDefault();
    this.setState({ showMessage: false });
    this.setState({ showMessage2: false });
    this.props.isLoading({
      message: "Adding Item",
      animate: true,
    });

    let that = this;
    const data = helpers.formToObject(e.target);

    api
      .post("/api/item/new", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ alert_message: "success" });
          this.setState({ showOverlay: !this.state.showOverlay });
          this.props.setOverlay({
            message: "Item successfully added !",
            label: "success",
            type: "success",
            button: {
              type: "close",
              label: "OK",
              link: "javascript:void(0)",
            },
          });

          //this.setState({ showOverlay: !this.state.showOverlay });
        } else {
          this.setState({ alert_message: "Error" });
          this.props.setOverlay({
            message: response.data.error,
            label: "warning",
            type: "fail",
            button: {
              type: "close",
              label: "OK",
              link: "javascript:void(0)",
            },
          });
        }
        this.props.doneLoading();
      })
      .catch((err) => this.props.doneLoading());
  };
  changeLimit = (e) => {
    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1,
      },
      () => this.loadTasks()
    );
  };

  updateFilter = (event, name) => {
    let that = this;
    const value = event.target ? event.target.value : event.value;

    if (window.ga)
      window.ga("send", "event", "SitesList", "updateFilter", name);

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function () {
        that.props.isLoading();
        that.loadTasks(1);
      }
    );
  };
  handleEscape = (e) => {
    if (e.keyCode === 27) {
      this.setState({ activeOverlay: null });
    }
  };
  componentDidMount() {
    /* if (this.props.match.params.taskid && this.props.match.params.taskid) {
      this.updateFilter({ value: this.props.match.params.taskid }, "pm");
    } else if (this.props.match.params.agentid) {
      this.updateFilter({ value: this.props.match.params.agentid }, "agent");
    } else if (this.props.match.params.site) {
      this.updateFilter({ value: this.props.match.params.site }, "site");
    } else this.loadTasks();
    api.get("/api/sites/fetch/filter?limit=-1") //this for filter sites 
    .then((response) => {
      if (response.data.success) {
        
        this.setState({sites: response.data.sites })
        console.log("sites===>",Object.keys(response.data.sites[0]))
        }
    }); */
    api
      .get("/api/sites/fetch/filter?limit=-1") //this for filter sites
      .then((response) => {
        if (response.data.success) {
          this.setState({ sites: response.data.sites });
          console.log("sites===>", Object.keys(response.data.sites[0]));
        }
      });
    document.addEventListener("keyup", this.handleEscape);
    this.loadTasks();
  }

  loadTasks(p = this.state.current) {
    let nstate = this.state;
    let that = this;
    let crits = buildFilters(this.state.filters);
    //page=${p}&limit=${this.state.limit}&${crits}
    api
      .get(
        `/api/inventory/items/fetch?page=${p}&limit=${this.state.limit}&${crits}`
      )
      // api.get(`/api/inventory/items/fetch?${crits}&page=${p}&limit=${this.state.limit}`)
      .then(function (response) {
        //console.log('response.data.successssssss',response.data.item_structure)
        // api.get(`/api/inventory/task/fetch?${crits}&page=${p}&limit=${this.state.limit}`).then(function(response) {
        if (response.data.success) {
          response.data.sites.map(() => {
            nstate.rows.push({ hidden: true });
          });
          that.setState({
            tasks: response.data.sites,
            pages: response.data.pages,
            rows: nstate.rows,
            total: response.data.total,
            item_sous_structure: response.data.item_sous_structure,
            item_structure: response.data.item_structure,
            item_direction: response.data.item_direction,
          });
        } else {
          if (response.data.errcode === 101) {
            that.props.setAuth(false);
          }
        }

        that.props.doneLoading();
      });
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.selectedValue == "") {
      if (this.state.sites[0] != null) {
        this.setState({ selectedValue: this.state.sites[0].site_id });
      }
    }
    if (prevState.selectedValue !== this.state.selectedValue) {
      // Perform action when the value state changes
      api
        .get(`api/sites/cosites/${this.state.selectedValue}`)
        .then((response) => {
          if (response.data.success) {
            this.setState({ cosites: response.data.data });
          }
        });
    }
    if (this.state.selectedStructure == "") {
      if (this.state.cosites[0] != null) {
        this.setState({ selectedStructure: this.state.cosites[0].id });
      }
    }
    if (prevState.selectedStructure !== this.state.selectedStructure) {
      // Perform action when the value state changes
      api
        .get(`/api/cosites/costructures/${this.state.selectedStructure}`)
        .then((response) => {
          if (response.data.success) {
            this.setState({ costructures: response.data.data });
          }
        });
    }
  };
  exportAnomalies = (e) => {
    if (window.ga) window.ga("send", "event", "TaskList", "exportAnomalies");

    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;
    //const url = remote + `/api/inventory/task/fetch?${crits}&limit=-1&anomalies`;
    const url =
      remote + `/api/inventory/items/fetch?${crits}&limit=-1&anomalies`;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };

  exportPMS = (e) => {
    e.preventDefault();

    let crits = buildFilters(this.state.filters);

    let remote = Constants.apiURL;
    //const url = remote + `/api/inventory/task/fetch?${crits}&limit=-1&export=true`;
    const url =
      remote + `/api/inventory/items/fetch?${crits}&limit=-1&export=true`;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };

  selectPage = (p) => {
    if (window.ga) window.ga("send", "event", "TaskList", "selectPage");

    this.props.isLoading();
    this.setState({ current: p });
    this.loadTasks(p);
  };

  exportTMDB = (e) => {
    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;
    const url = remote + Constants.apiEndPoints.EXPORT_TMDB;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  };

  render() {
    //const items_sous_structure = this.state.tasks.filter((option, index) =>  this.state.tasks.indexOf(option) === index);

    return (
      <React.Fragment>
        {this.state.activeOverlay === "export" && (
          <SitesExport
            closeModal={() => this.setState({ activeOverlay: null })}
            open={true}
            getAction={this.getCrits}
          />
        )}
        <div className={"container-header"}>
          Items
          <div className={"float-right"}>
            <a
              href={"/items"}
              onClick={this.toggleOverlay}
              className={"btn btn-primary btn-block mb-2"}
            >
              <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
              nouveau item
            </a>
          </div>
          {/*  <Restricted scope={"pm"} permission={"export_task"}>
            <button
              type="button"
              className={"btn btn-primary btn-block  ml-2"}
              onClick={(e) => this.exportInventaire(e)}
            >
              
              <FontAwesomeIcon icon={faFileExport} className={"mr-2"} />
              Rapport inventaire
            </button>
          </Restricted> */}
          <Restricted scope={"pm"} permission={"export_task"}>
            <form
              action=""
              method="GET"
              onSubmit={(e) => this.exportInventaire(e)}
            >
              <input
                type="hidden"
                name="token"
                value={this.props.session.user.token}
              />
              <button
                type="submit"
                className={"btn btn-primary btn-block mb-2"}
              >
                Exporter Rapport{" "}
                <FontAwesomeIcon className={"ml-2"} icon={faFileExport} />
              </button>
            </form>
          </Restricted>
        </div>
        <div
          className={this.state.showOverlay ? "ui-overlay open" : "ui-overlay"}
        >
          <div className={"ui-overlay-content"}>
            <button
              data-package-id="0"
              onClick={this.toggleOverlay}
              className={"ui-overlay-close btn btn-danger btn-sm"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            <h5>Insérer un nouveau item</h5>

            <form onSubmit={this.formSubmit}>
              <div className="form-group mt-3">
                <div className={"form-group mt-3"}>
                  <label>Numéro inventaire</label>
                  <input
                    id="code"
                    required
                    name={"code"}
                    type={"text"}
                    className={"form-control"}
                  />
                </div>

                <div className={"form-group"}>
                  <label>Name</label>
                  <input
                    id="name"
                    required
                    name={"name"}
                    type={"text"}
                    className={"form-control"}
                  />
                </div>
                <div className={"form-group"}>
                  <label>Direction</label>
                  <select
                    name={"code_direction"}
                    className={"form-control"}
                    onChange={this.handleChange}
                  >
                    {/*  {this.state.item_direction.map((site) => <option key={site.site_code} value={site.site_code}>{site.site_name}</option>
                  )} */}

                    {this.state.sites.map((site) => (
                      <option key={site.site_id} value={site.site_id}>
                        {site.site_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className={"form-group"}>
                  <label> Structure</label>
                  <select
                    name={"code_stru"}
                    className={"form-control"}
                    onChange={this.handleChangeStructure}
                  >
                    {/*  {this.state.item_structure.map((task) => 
                  <option key={task.COD_STRU} 
                  value={task.COD_STRU}>{task.COD_STRU}</option>
                  )} */}

                    {this.state.cosites.map((cosite) => (
                      <option key={cosite.id} value={cosite.id}>
                        {cosite.site_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={"form-group"}>
                  <label>Sous Structure</label>
                  <select name={"code_s_stru"} className={"form-control"}>
                    {/* {
                  this.state.item_sous_structure.map((task)=>
                  <option key={task.COD_S_STR
                  } value={task.COD_S_STR
                  }>{task.COD_S_STR
                  }</option>
                  )} */}

                    {this.state.costructures.map((costructure) => (
                      <option key={costructure.id} value={costructure.id}>
                        {costructure.site_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className={"form-group"}>
                  <label>Numero Comptable</label>
                  <input
                    id="numero_comptable"
                    required
                    name={"numero_comptable"}
                    type={"text"}
                    className={"form-control"}
                  />
                </div>

                <div className={"form-group"}>
                  <label> valeur</label>
                  <input
                    id="valeur"
                    required
                    name={"valeur"}
                    type={"text"}
                    className={"form-control"}
                  />
                </div>

                <input
                  type="submit"
                  value="Envoyer"
                  className={"btn btn-primary btn-sm mt-5"}
                />
              </div>
            </form>
          </div>
        </div>

        <div className={"row"}>
          <div className={"col-md-2"}>
            <div className={"side-filters"}>
              {/* <form action="" method="GET"  onSubmit={e => this.exportAnomalies(e)} >
						<button type="submit" className={"btn btn-primary btn-block mb-2"}>Exporter les anomalies <FontAwesomeIcon className={"ml-2"} icon={faFileExport} /></button>
						</form> */}

              <Restricted>
                <React.Fragment>
                  <div
                    className={
                      this.state.showOverlayItem
                        ? "ui-overlay open "
                        : "ui-overlay"
                    }
                  >
                    <div
                      className={
                        "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
                      }
                    >
                      <button
                        data-package-id="0"
                        onClick={this.toggleOverlayItem}
                        className={"ui-overlay-close btn btn-danger btn-sm"}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>

                      <h5>Ajouter un nouveau items</h5>

                      <form
                        onSubmit={this.formSubmithandle}
                        action={Constants.apiURL + "/api/planningItems/new"}
                        method="post"
                      >
                        <input
                          type="hidden"
                          name="token"
                          value={this.props.session.user.token}
                        />
                        <div className={"form-group mt-3"}>
                          <label className={"font-exp"}>
                            Selectionnez le fichier items
                            <a
                              href={
                                Constants.apiURL +
                                "/template_items_planning.xlsx"
                              }
                              className={"btn btn-sm btn-link"}
                              target="_blank"
                            >
                              {" "}
                              (voir template){" "}
                            </a>
                          </label>
                          <label>Fichier</label>
                          <input
                            type="file"
                            name="planning_file"
                            className={"form-control"}
                          />
                        </div>
                        <div className={"form-group"}>
                          <button
                            disabled={this.state.loading}
                            type="submit"
                            className={"btn btn-success float-right"}
                          >
                            {this.state.loading ? (
                              <FontAwesomeIcon icon={faSpinner} spin={true} />
                            ) : (
                              <>
                                <FontAwesomeIcon
                                  icon={faArrowUp}
                                  className={"mr-2"}
                                />
                                Envoyer
                              </>
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </React.Fragment>

                <Restricted scope={"pm"} permission={"edit_agent_site"}>
                  <a
                    href={"/item"}
                    onClick={this.toggleOverlayItem}
                    className={"btn btn-primary btn-block mb-2"}
                  >
                    <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                    Importer
                  </a>
                </Restricted>

                <form
                  action=""
                  method="POST"
                  onSubmit={(e) => this.exportPMS(e)}
                >
                  <input
                    type="hidden"
                    name="token"
                    value={this.props.session.user.token}
                  />
                  <button
                    type="submit"
                    className={"btn btn-primary btn-block mb-2"}
                  >
                    <FontAwesomeIcon icon={faFileExport} className={"mr-2"} />{" "}
                    Exporter
                  </button>
                </form>
              </Restricted>

              <h4>Recherche</h4>

              <FiltersWrap update={this.updateFilter} session={this.props.user}>
                <FilterElement label={"Code Inventaire"} name={"site_code"} />
                <FilterElement label={"Nom Inventaire"} name={"item_name"} />
                <FilterElement
                  label={"Code Structure"}
                  name={"item_COD_STRU"}
                />
                <FilterElement
                  label={"Code Sous Structure"}
                  name={"item_sstr"}
                />
                <div className={"form-group"}>
                  <label>ID inventaire </label>
                  <Select
                    classNamePrefix={"custom-select"}
                    options={this.props.filters.task_inventaire}
                    placeholder="inventaire"
                    onChange={(e) => this.updateFilter(e, "task_inventaire")}
                  />
                </div>
              </FiltersWrap>
            </div>
          </div>

          <div className={"col-md-10"}>
            <div className={"row mb-3"}>
              <div className={"col-md-6 items-found"}>
                <p>
                  <b>{this.state.total}</b> Item trouvés
                </p>
              </div>
              <div className={"col-md-6 per-page-wrap"}>
                <div className={"per-page-content"}>
                  <p>Résultat par page</p>
                  <Select
                    classNamePrefix={"custom-select"}
                    onChange={this.changeLimit}
                    className={"per-page nm"}
                    options={this.props.filters.result_per_page}
                    value={{
                      label: this.state.limit,
                      value: this.state.limit,
                    }}
                  />
                </div>
                <Pagination data={this.state} handleClick={this.selectPage} />
              </div>
            </div>

            <TaskList
              data={this.state}
              rows={this.state.rows}
              handlers={this.handlers}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const TaskListConsumer = (props) => (
  <SocketContext.Consumer>
    {(socket) => <TaskListContainer {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default connect(mapStateToProps, mapDispatchToProps)(TaskListConsumer);
