import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import InstallationListContainer from '../Containers/Installation/InstallationListContainer';
import SitesListContainer from "../Containers/Delivery/SitesContainer";
import AddressNewContainer from "../Containers/Delivery/AddressNewContainer";
import PlanningList from "../Components/Planning/PlanningList";
import SiteDetail from "../Components/Addresses/SiteDetail";
import TaskListContainer from "../Containers/Delivery/TaskListContainer";
import AnomaliesContainer from "../Containers/Delivery/AnomaliesContainer";
import CalendarPage from "../Components/Planning/CalendarPage";
import DashboardWrapper from "../Components/Dashboard/Pages/DashboardWrapper";
import Form from '../Components/Form/index'
function mapStateToProps(state) {
  return { auth: state.session.auth, session: state.session };
}

class InstallationRouter extends Component {
  render() {
    return (
       
      <Switch>

            <Route exact path="/installation" component={InstallationListContainer} />
            

      </Switch>
       
    );
  }
}

export default connect(mapStateToProps)(InstallationRouter);