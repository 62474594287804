import React, { useEffect , useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck, faFileExport } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";
import {convertDMSToDD} from '../../../../Utils/Helpers'
import {isJsonString} from '../../../../Utils/Helpers'
import Modal from 'react-bootstrap/Modal'
import { MapContainer, TileLayer, Marker, Popup , GeoJSON} from 'react-leaflet';
import "leaflet/dist/images/marker-shadow.png";
import ReactLeafletKml from 'react-leaflet-kml';
import Constants from '../../../../Utils/Constants';
import { useSelector } from 'react-redux';


const moment = require("moment");

const capacity = Array(30).fill(0);
const countC = [3, 7, 7, 9, 9]


const SubSite = ({ data, active, geo, onchange, id, task, code,cosite,  ...props }) => {
    //console.log('this ma[[' + JSON.stringify(cosite))
    const { session } = useSelector(state => ({ session: state.session }));
    const [show, setShow] = useState(false);
    const [selectedTable, setSelectedTable] = useState('');
    const [count, setCount] = useState(0);
    const [selectedItem, setSelectedItem] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const exportForm = useRef(null);
    const detailInput = useRef(null);

    // const [items, setItems] = useState(data.items);
    // const [items_rad_ant, setItemsRadAnt] = useState(data.items_rad_ant);
    // const [items_trans, setItemsTrans] = useState(data.items_trans);
    // const [items_mast, setItemsMast] = useState(data.items_mast);
    // const [items_mast2, setItemsMast2] = useState(data.items_mast2);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const exportPMBatXLSX = () => {
      console.log('aef')
      //setLoading(true);
    
      exportForm.current.action = Constants.apiEndPoints.EXPORTXLSXZONE_SSR_TASK(9);
      exportForm.current.submit();
    }



  return (
    <>
     <form method="post" ref={exportForm} target={"_blank"} >
        <input type="hidden" name={"pm"} ref={detailInput} />
        <input type="hidden" name={"token"} value={session.user.token} />
      </form>
    <button className="btn btn-success" onClick={() => exportPMBatXLSX()}> <FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} /> Export XLSX  </button> 
     <h6>Informations générales (Fiche Technique)</h6>
      <div className={"row col-md-6"}>
        <label>Date de début du site survey : </label>
        {data.ssr_start_date}
      </div>

      <div className={"row col-md-6"}>
        <label>Heure de début du site survey : </label>
        {data.ssr_start_time}
      </div>

      <div className={"row col-md-6"}>
        <label>Date de fin du site survey : </label>
        {moment(data.finished_at).format("YYYY-MM-DD")}
      </div>

      <div className={"row col-md-6"}>
        <label>Heure de fin du site survey : </label>
        {moment(data.finished_at).format("HH:mm:ss")}
      </div>

      <div className={"row col-md-6"}>
        <label>DOT : </label>
        {data.site.site_name}
      </div>
      <div className={"row col-md-6"}>
        <label>CMT : </label>
        {data.site.cmt}
      </div>

      <div className={"row col-md-6"}>
        <label>Promoteur : </label>
        {data.site.promoteur}
        {data.site.promoteurOther}
      </div>

      <div className={"row col-md-6"}>
        <label>Capacité logements : </label>
        {data.site.capacite_logements}
      </div>

      <div className={"row col-md-6"}>
        <label>Type de Technologie</label>
        {data.site.type_technologie}
      </div>

      <div className={"row col-md-6"}>
        <label>Code Zone : </label>
        {data.site.site_code}
      </div>

      <div className={"row col-md-6"}>
        <label>Code site: </label>
        {code}
      </div>

      <div className={"row col-md-6"}>
        <label>Code Action : </label>
        {data.site.action_code}
      </div>

      <div className={"row col-md-6"}>
        <label>Type OLT : </label>
        {data.site.type_olt}
      </div>
      <div className={"row col-md-6"}>
        <label>GPS: </label>
        {data.site.site_address_position}
      </div>
     

      
      <h6>Donnees à récolter</h6>

      <label>Périmetre de la zone  : </label>

      {/* <div className={"col-md-6 map mb-2"}>
              
        <MapContainer center={[36.000, 2.09]} zoom={5} scrollWheelZoom={true} className="map"  style={{height: '180px'}}>
              
              <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
                {cosite.site_file &&  <ReactLeafletKml kml={new DOMParser().parseFromString(cosite.site_file, "text/xml")} />}
        </MapContainer>
      </div> */}
      {data.Path && data.Path.length > 0 &&  <div className={"col-md-12"}>
          <div className="row border">
              <div className="col-md-4"><b>Numéro </b></div>
              <div className="col-md-8"><b>GPS</b></div>
              
          </div>
          
          {data.Path.map((item, i) => (
            <div className="row border">
              <div className="col-md-4">{ i + 1 }</div>
              <div className="col-md-8">{item.perm ? item.perm : '' }</div>
            </div>
          ))}
      </div>}
 
      <div className={"row col-md-6"}>
        <label>Nom de la rue: </label>
        {data.site.rue_name}
      </div>

      <label>Villas  : </label>
      {data.villas.length > 0 &&  <div className={"col-md-12"}>
          <div className="row border">
              <div className="col-md-2"><b>Numéro de Bâtiment</b></div>
              <div className="col-md-3"><b>Type d'habitation</b></div>
              <div className="col-md-2"><b>Nombre de commerce</b></div>
              <div className="col-md-2"><b>Nombre d'utilisateurs</b></div>
              <div className="col-md-3"><b>Photos</b></div>
          </div>
          
          {data.villas.map(item => (
            <div className="row border">
              <div className="col-md-2">{item.num_bat ? item.num_bat : '' }</div>
              <div className="col-md-3">{item.type_hab ? item.type_hab : '' }</div>
              <div className="col-md-2">{item.nbr_commerce ? item.nbr_commerce : '' }</div>
              <div className="col-md-2">{item.nbr_user ? item.nbr_user : '' }</div>
              <div className="col-md-3">{item.photos && item.photos.length > 0 ? <PhotoItem photo={item.photos[0]}  /> : '/' }</div>
            </div>
          ))}
      </div>}


      <label>Bâtiments : </label>
      {data.bat.length > 0 &&  <div className={"col-md-12"}>
          <div className="row border">
              <div className="col-md-2"><b>Numéro de Bâtiment</b></div>
              <div className="col-md-2"><b>Type d'habitation</b></div>
              <div className="col-md-2"><b>Nombre logement par palier</b></div>
              <div className="col-md-2"><b>Nombre de commerce</b></div>
              <div className="col-md-2"><b>Nombre d'utilisateurs</b></div>
              <div className="col-md-2"><b>Photos</b></div>
          </div>
          
          {data.bat.map(item => (
            <div className="row border">
              <div className="col-md-2">{item.num_bat ? item.num_bat : '' }</div>
              <div className="col-md-2">{item.type_hab ? item.type_hab : '' }</div>
              <div className="col-md-2">{item.nbr_log_pal ? item.nbr_log_pal : '' }</div>
              <div className="col-md-2">{item.nbr_commerce ? item.nbr_commerce : '' }</div>
              <div className="col-md-2">{item.nbr_user ? item.nbr_user : '' }</div>
              <div className="col-md-2">{item.photos && item.photos.length > 0 ? <PhotoItem photo={item.photos[0]}  /> : '/' }</div>
            </div>
          ))}
      </div>}


      <label>Chambres : </label>
      {data.Chambre.length > 0 &&  <div className={"col-md-10"}>
          <div className="row border">
              <div className="col-md-6"><b>Type de chambre</b></div>
              <div className="col-md-6"><b>Photos</b></div>
          </div>
          
          {data.Chambre.map(item => (
            <div className="row border">
              <div className="col-md-6">{item.type_chambre ? item.type_chambre : '' }</div>
              <div className="col-md-6">{item.photos && item.photos.length > 0 ? <PhotoItem photo={item.photos[0]}  /> : '/' }</div>
            </div>
          ))}
      </div>}

      <label>Canalisations : </label>
      {data.canalisation.length > 0 &&  <div className={"col-md-10"}>
          <div className="row border">
              <div className="col-md-4"><b>Nombre des fourreaux occupés</b></div>
              <div className="col-md-4"><b>Nombre total des fourreaux</b></div>
              <div className="col-md-4"><b>Type des fourreaux</b></div>
          </div>
          
          {data.canalisation.map(item => (
            <div className="row border">
              <div className="col-md-4">{item.nbr_fourreaux ? item.nbr_fourreaux : '' }</div>
              <div className="col-md-4">{item.nbr_fourreaux_total ? item.nbr_fourreaux_total : '' }</div>
              <div className="col-md-4">{item.type_fourreaux ? item.type_fourreaux : '' }</div>
            </div>
          ))}
      </div>}

      <label>Poteau : </label>
      {data.Poteau.length > 0 &&  <div className={"col-md-10"}>
          <div className="row border">
              <div className="col-md-6"><b>Poteau</b></div>
              <div className="col-md-6"><b>Photos</b></div>
          </div>
          
          {data.Poteau.map((item, i) => (
            <div className="row border">
              <div className="col-md-6">{i +1 }</div>
              <div className="col-md-6">{item.photos && item.photos.length > 0 ? <PhotoItem photo={item.photos[0]}  /> : '/' }</div>
            </div>
          ))}
      </div>}
      <label>Poteau Nouveau: </label>
      {data.PoteauN.length > 0 &&  <div className={"col-md-10"}>
          <div className="row border">
              <div className="col-md-6"><b>Poteau</b></div>
              <div className="col-md-6"><b>Photos</b></div>
          </div>
          
          {data.PoteauN.map((item, i) => (
            <div className="row border">
              <div className="col-md-6">{i +1 }</div>
              <div className="col-md-6">{item.photos && item.photos.length > 0 ? <PhotoItem photo={item.photos[0]}  /> : '/' }</div>
            </div>
          ))}
      </div>}
    </>
  );
};

export default SubSite;
