import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import api from "../../../Utils/Api"; 
import Restricted from "../../../Utils/Restricted";
  
import { connect } from "react-redux";
import { setAuthAction, doneLoading, startLoading, setOverlay } from  '../../../Redux/actions/index';
const mapStateToProps = (state) => {
  return {
    auth: state.session.auth,
    user: state.session.user,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => ({
   setOverlay: (e) => dispatch(setOverlay(e)),
   
});

const ItemEditOverlay = ({toggle, open, item_s_stru,item_stru,item_cod_dr,task,cosite,structure, editTask,data,cositecode,id,reload, scope = "pm", ...props
   
}) => { 

console.log('item_stru',item_stru)

 const [response,setResponse]=useState('');
 const etat = [
  'Bon',
  'Moyen',
  'Mauvais',
];
const [selectedValue, setSelectedValue] = useState(task.etat_item); 

  const submitSiteForm = (e) => {
    
    

    e.preventDefault();

    const formdata = new FormData(e.target);

    let data = {};
    for (var [key, value] of formdata.entries()) data[key] = value;
   
    data["user"] = props.user;
    console.log("ddddddddddddddddata",data)

    toggle();   
      api.post("/api/item/tache/edit/" + task.code_item+"/"+item_s_stru+"/"+id, data).then(
      (res) => {
       
       setResponse(res.status)
       console.log('res st',res.status)
       if(res.status==200){
        console.log('alert')
        task.name_item=data.item_name
    task.etat_item=data.item_etat
          props.setOverlay({
          message: 'Item mis à jour avec avec succès !',
          label: "success",
          type: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)"
          }
        })
    }else{
      props.setOverlay({
        message: "une erreur s'est produite lors de la mise à jour de Item!",
        label: "Error",
        type: "Error",
        button: {
          type: "close",
          label: "OK",
          link: "javascript:void(0)"
        }
      })
    }
    });   
    
   
  };
  
 const handleChange=(e)=>{
  console.log('handle change ',e.target.value)
  setSelectedValue(e.target.value);
 }

  return (
   
    <>
       

      <div className={"ui-overlay" + (open ? " open" : "")}>
        <div className={"ui-overlay-content anomalie-history col-md-6"}>
          <button
            onClick={toggle}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5>Modifier Item </h5>

          <form onSubmit={submitSiteForm}>
            {open && (
              <>
                {/* <input type="hidden" name="userid" value={this.props.user.userid} /> */}
 
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Nom Item </label>
                  <input
                    type="text"
                    name="item_name"
                    className={"form-control col-md-8"}
                      defaultValue={task.name_item}  
                  />
                </div>

                
                
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Num Inventaire</label>
                  <input
                    type="item_code"
                    disabled={true}
                    className={"form-control col-md-8"}
                   defaultValue={task.code_item}  
                  />
                </div>

               
                
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Etat</label>
                 {/*  <input
                    type="text"
                    name="item_etat"
                    className={"form-control col-md-8"}
                    defaultValue={task.etat_item}  
                  /> */}

<select name={"item_etat"}
        className={"form-control col-md-8"} 
        /* value={etat[1]} onChange={handleChange} */ >
              

{etat.map((type) => 
                
                  <option key={type} value={type}>{type}</option> 
                )
}


              </select>







                </div>
              
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Code </label>
                  <input
                    type="text"
                    name="item_id"
                    className={"form-control col-md-8"}
                    defaultValue={task.id_item}  
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Code Structure</label>
                  <input
                    type="text"
                    name="item_stru"
                    className={"form-control col-md-8"}
                    defaultValue={item_stru}  
                  />
                </div>

              

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Code Sous Structure</label>
                  <input
                    type="text"
                    name="item_s_stru"
                    className={"form-control col-md-8"}
                   defaultValue={item_s_stru}
                     
                  />
                </div>
               

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Code Direction</label>
                  <input
                    type="text"
                    name="item_cod_dr"
                    className={"form-control col-md-8"}
                    defaultValue={item_cod_dr}
                    
                     
                  />
                </div>                                
             
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Date d'acquisition</label>
                  <input
                    type="date"
                    name="item_date_acquisation"
                    className={"form-control col-md-8"}
                    
                     
                  />
                </div> 

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <input
                    type="submit"
                    className={"btn btn-primary col-md-12"}
                    value="Valider les modifications"
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemEditOverlay); 