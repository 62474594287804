import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SitesListContainer from "../Containers/SSR/SitesContainer";
import AddressNewContainer from "../Containers/SSR/AddressNewContainer";
import PlanningList from "../Components/Planning/PlanningList";
import SiteDetail from "../Components/Addresses/SiteDetail";
//import TaskListContainer from "../Containers/SSR/TaskListContainer";
import ItemListContainer from "../Containers/Tasks Inventory/ItemListContainer";
import AnomaliesContainer from "../Containers/SSR/AnomaliesContainer";
import CalendarPage from "../Components/Planning/CalendarPage";
import DashboardWrapper from "../Components/Dashboard/Pages/DashboardWrapper";

function mapStateToProps(state) {
  return { auth: state.session.auth, session: state.session };
}

class SSRRouter extends Component {
  render() {
    return (
      <Switch>
 
 
            <Route exact path="/items" component={ItemListContainer} />
           

      </Switch>
    );
  }
}

export default connect(mapStateToProps)(SSRRouter);
