import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SitesListContainer from "../Containers/GPM/Site/SitesContainer";
import AddressNewContainer from "../Containers/Delivery/AddressNewContainer";
import SiteDetail from "../Components/Addresses/SiteDetail";
import TaskListContainer from "../Containers/CM/TaskListContainer";

function mapStateToProps(state) {
  return { auth: state.session.auth, session: state.session };
}

class CMRouter extends Component {
  render() {
    return (
    <Switch>
        <Route exact path="/cm" component={TaskListContainer} />
        <Route exact path="/cm/sites" component={SitesListContainer} />
        <Route exact path="/cm/task" component={TaskListContainer} />
    </Switch> 
    );
  }
}

export default connect(mapStateToProps)(CMRouter);
