//const apiURL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'http://localhost/netfersmartsolutions-witrack-rollout-backend-984317795bff/public';
// const apiURL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'https://api.wtrack-rollout.n-smartsolutions.com';

//SAM
//const apiURL = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'https://api.atrollout.witrack.digital';
/* const apiURL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : "https://api.saa.witrack.link"; */

  const apiURL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'https://api.saa.witrack.link';


//const apiURL =  "http://127.0.0.1:8000"
//cerine
// https://wtrack-rollout.n-smartsolutions.com

export default {
  apiURL,
  apiEndPoints: {
    CONNECT: "/api/user/connect",
    FETCH_NOTIFICATIONS: "/api/notification/fetch",
    FETCH_FILTERS: "/api/form/filters",
    FETCH_TASK_GLOBAL: "/api/taskGlobal/fetch",
    FETCH_TASK_DETAIL: (id) => "/api/task/fetch?detail=true&pm=" + id,
    FETCH_SSR_TASK_DETAIL: (id) => "/api/ssr/task/fetch?detail=true&pm=" + id,
    FETCH_IVENTORY_TASK_DETAIL: (id) => "api/inventory/task/details/" + id,
    /*FETCH_IVENTORY_TASK_DETAIL: id => '/api/inventory/task/fetch?detail=true&pm='+id,*/
    EXPORT_TASK: (id) => apiURL + "/api/pm/export/igeneral/" + id,
    EXPORT_TASK_ANOMALIES: (id) =>
      apiURL + "/api/anomalies/checks_export/" + id,
    EXPORT_SSR_TASK: (id) => apiURL + "/api/inventairephysique/export/" + id,

    EXPORT_RAPPORT_INVENTAIRE: "api/invetaire/exportInventaire/",

    EXPORT_INVENTAIRE_TASK: (id) => apiURL + "/api/inventaire/export/" + id,
    EXPORT_PRE_INVENTAIRE_TASK: (id) =>
      apiURL + "/api/preinventaire/export/" + id,
    EXPORT_SSR_TASK_ANOMALIES: (id) =>
      apiURL + "/api/ssr/anomalies/checks_export/" + id,
    EXPORT_TASK_GPM: (id) => apiURL + "/api/gpm/export/igeneral/" + id,
    EXPORT_TASK_ANOMALIES_GPM: (id) =>
      apiURL + "/api/gpm/anomalies/checks_export/" + id,
    EXPORTPDF_SSR_TASK: (id) => apiURL + "/api/ssr/exportxlsx/ssr/" + id,
    EXPORTXLSXZONE_SSR_TASK: (id) =>
      apiURL + "/api/ssr/exportxlsxZone/ssr/" + id,
    FETCH_IVENTORY_ITEM_DETAIL: (id) =>
      "/api/inventory/items/fetch?detail=true&pm=" + id,
    FETCH_IVENTORY_TASK_AGENT_DETAIL: (id) =>
      "/api/inventory/task/agent/fetch?detail=true&pm=" + id,
    EXPORT_INVENTAIRE_PHYSIQUE: (key, id) =>
      apiURL + "/api/invetaire/export/inventory/" + key + "/" + id,
    EXPORT_PRE_INVENTAIRE: (key, id) =>
      apiURL + "/api/invetaire/export/preinventory/" + key + "/" + id,
    EXPORT_INVENTAIRE: (key, id) =>
      apiURL + "/api/invetaire/export/inventaire/" + key + "/" + id,
    EXPORT_TMDB: "/api/tmdb/export",
    EXPORT_BIENS: (key, id) => apiURL + "/api/bien/export/" + key + "/" + id,

    EXPORT_INVENTAIRE_FICHE_LOCAL: (id) =>
      apiURL + "/api/invetaire/export/fichelocal/" +id,
  },
};
